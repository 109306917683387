<template>
  <v-layout>
    <Header />
    <SnackBar />

    <v-main>
      <v-overlay
        :absolute="true"
        v-model="this.$store.state.overlay"
        class="align-center justify-center"
      >
        <v-progress-circular indeterminate size="64"> </v-progress-circular>
      </v-overlay>
      <div class="page_content">
        <router-view></router-view>
      </div>
    </v-main>
  </v-layout>
</template>

<script>
import Header from "@/components/Header";
import SnackBar from "@/components/SnackBar";

export default {
  name: "Index",
  components: { Header, SnackBar },
};
</script>

export default {
  methods: {
    /**
     * 引数で指定された日付をYYYY年MM月DD日形式に変換する
     * @param {String} orgDate 元のデータ
     * @returns {String} YYYY年MM月DD日形式の日付を返す
     */
    cvtDateToJpn(orgDate) {
      var destDate = new Date(orgDate);
      var year = destDate.getFullYear();
      var month = destDate.getMonth() + 1;
      var date = destDate.getDate();
      return year + '年' + month + '月' + date + '日';
    },
    /**
     * 現在の日付をYYYY-MM-DD形式で取得する
     * @returns {String} YYYY-MM-DD形式の日付を返す
     */
    getNowDate() {
      //現在の日付を取得する
      var nowDate = new Date();
      var year = nowDate.getFullYear();
      var month = nowDate.getMonth() + 1;
      var date = nowDate.getDate();

      //月と日をゼロパディングする
      month = ('0' + month).slice(-2);
      date = ('0' + date).slice(-2);
      return nowDate = year + '-' + month + '-' + date;
    },
  }
}
//import axios from "axios";

export default {
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 0,
      total: 0,
      from: 0,
      to: 0,
      items: [],
      formFocus: false,
      isLoading: false,
    };
  },
  mounted() {
    (async () => {
      const el = document.getElementById("search_form");
      if (el) {
        el.addEventListener("keydown", this.searchByEnter);
      }
    })();
  },
  methods: {
    searchByEnter(e) {
      const component = this;
      if (e.keyCode === 13 && e.target.type != "textarea") {
        component.search(component.getSearchBase());
      }
    },
    //pageと検索条件からAPIリクエストを実行
    async search(
      params,
      onLoad = false,
      popupUrl = false,
      successCallback = () => {},
      errorCallback = () => {}
    ) {
      this.isLoading = true;

      //保存された検索条件の削除
      if (params.clear !== undefined) {
        this.resetParams();
      }

      //検索条件の復元
      //onLoad = trueの時のみ復元する。
      if (onLoad && this.getRestoreParams() !== null) {
        params = this.getRestoreParams();
      }

      await this.$axios
        .get(this.searchURL, { params })
        .then((response) => {
          //ページング用メタ情報
          //全件数
          this.total = response.data.total;
          //ページ数
          this.pageCount = response.data.last_page;
          //1ページあたりの件数
          this.itemsPerPage = response.data.per_page;

          this.from = response.data.from;

          this.to = response.data.to;

          //データ
          if (response.data.data && !Array.isArray(response.data.data)) {
            //customPaginate returns object, change to array
            this.items = Object.keys(response.data.data).map((key) => {
              return response.data.data[key];
            });
          } else {
            //laravel paginate returns array
            this.items = response.data.data;
          }

          //現在のページを保持する
          this.page = response.data.current_page;
          this.isLoading = false;

          //URLを変更
          if (popupUrl == false) {
            //avoid adding data from popup url to window url
            if (!("router_change" in params)) {
              this.$router
                .push({
                  query: params,
                })
                .catch((err) => {
                  err;
                });
            }
          }
          //ページTOPへ
          if (params.scrollFlg == true) {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }
          //検索の値を復元
          for (const searchKey of this.searchScheme) {
            if (params[searchKey] !== undefined) {
              this.searchForm[searchKey] = params[searchKey];

              if (this.select) {
                //select exist or not (if select is exist data value must be integer)
                for (const selectKey of this.select) {
                  if (searchKey == selectKey) {
                    if (params[searchKey] == "all") {
                      this.searchForm[searchKey] = params[searchKey];
                    } else {
                      this.searchForm[searchKey] = parseInt(params[searchKey]);
                    }
                  }
                }
              }
            }
          }
          this.saveParams(params);
          successCallback(response);
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          // バリデーションエラー
          if (error.response.status === 422 || error.response.status === 400) {
            this.$store.commit("setMessege", {
              text: error.response.data.errors,
              color: "warning",
            });
            errorCallback();
          }
        });
    },
    //ページングがクリックされた時
    changePage(value) {
      this.page = value.page;
      this.pageCount = value.pageCount;
      this.itemsPerPage = value.itemsPerPage;
      let query = this.getSearchBase();
      query.page = value.page;
      this.search(query);
      this.scrollToTop();
    },
    //検索条件のベースを返す
    getSearchBase() {
      let data = { page: 1 };
      for (const searchKey of this.searchScheme) {
        if (this.searchForm[searchKey]) {
          if (
            Array.isArray(this.searchForm[searchKey]) &&
            this.searchForm[searchKey].length
          ) {
            data[searchKey] = this.searchForm[searchKey];
          } else if (
            this.searchForm[searchKey] != null &&
            this.searchForm[searchKey] != ""
          ) {
            data[searchKey] = this.searchForm[searchKey];
          }
        }
      }
      return data;
    },
    getSearchScheme() {
      return this.searchScheme;
    },
    tableSort(items, index, isDescending) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
        return items;
      }
      let direction = "asc";
      if (isDescending[0]) {
        direction = "desc";
      }
      if (
        this.searchForm["sort"] != index[0] ||
        this.searchForm["direction"] != direction
      ) {
        this.searchForm["sort"] = index[0];
        this.searchForm["direction"] = direction;
        let query = this.getSearchBase();
        this.search(query);
      }
      return items;
    },
    saveParams(params) {
      //検索条件の保存
      sessionStorage.setItem(this.getStorageKey(), JSON.stringify(params));
    },
    resetParams() {
      sessionStorage.removeItem(this.getStorageKey());
    },
    getRestoreParams() {
      return JSON.parse(sessionStorage.getItem(this.getStorageKey()));
    },
    getStorageKey() {
      return location.pathname.replace(/\/$/, "");
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};

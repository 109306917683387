import { createStore } from "vuex";
import createMutationsSharer from "vuex-shared-mutations";

export default createStore({
  state: {
    //ヘッダ フラッシュメッセージ
    message: {
      text: "",
      color: "success",
      view: false,
      timeout: 5000,
    },
    overlay: false,
    counter: 0,
  },
  getters: {},
  mutations: {
    setMessage(state, { text, color }) {
      state.message.text = text;
      state.message.color = color;
      if (color == "error") {
        state.message.timeout = -1;
      }
      state.message.view = true;
    },
    increment(state, payload) {
      state.counter = state.counter + payload;
    },
    resetMessage(state) {
      state.message.text = "";
      state.message.view = false;
    },
    setOverlay(state, { overlay }) {
      state.overlay = overlay;
    },
  },
  actions: {},
  modules: {
    auth: {
      state: {
        authuser: {},
        authrole: null,
        loggedout: true,
      },
      mutations: {
        setAuthUser(state, { authuser, authrole }) {
          state.authuser = authuser;
          state.authrole = authrole;
          state.loggedout = false;
        },
        resetAuthUser(state) {
          state.authuser = {};
          state.authrole = null;
          state.loggedout = true;
        },
        updateLoginState(state, { loggedout }) {
          state.loggedout = loggedout;
        },
        setAuthEnabled(state, { isEnabled }) {
          state.authuser.is_enabled = isEnabled;
        },
      },
      namespaced: true,
    },
    env: {
      state: {
        app_state: "default",
      },
      mutations: {
        setAppState(state, { app_state }) {
          state.app_state = app_state;
        },
      },
      namespaced: true,
    },
  },
  plugins: [
    createMutationsSharer({
      predicate: [
        "auth/setAuthUser",
        "auth/resetAuthUser",
        "auth/updateLoginState",
        "auth/setAuthEnabled",
        "env/setAppState",
      ],
    }),
  ],

});
